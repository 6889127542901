import $ from 'jquery'

$(document).ready(function () {

    

    if ($(".map__holder").length) {

        function checkScroll() {
            $('.wpgmp_listing_container').each(function () {
                if (this.scrollHeight > this.clientHeight) {
                    $(this).addClass('has-scroll');
                } else {
                    $(this).removeClass('has-scroll');
                }
            });
        }

        // Check initially and on content changes
        checkScroll();
        setInterval(checkScroll, 10); // Check periodically

        var waitForListing = setInterval(function () {
            var listingContainer = $('.map .wpgmp_listing_container');
            if (listingContainer.length) {
                var parentElement = listingContainer.parent();
                parentElement.addClass('map__sidebar__listing');
                parentElement.prepend('<h3 class="sidebar-title">Partner schools</h3>');
                clearInterval(waitForListing);
            }
        }, 100);


        var waitForMap = setInterval(function () {
            if ($('.wpgmp_tab_item').length) {
                // Bind click event
                $('.wpgmp_tab_item input[type="checkbox"]').bind('click', function () {
                    console.log('clicked');
                    if ($(this).is(':checked')) {
                        // Uncheck other checkboxes and remove their active class
                        $('.wpgmp_tab_item input[type="checkbox"]').not(this).each(function () {
                            $(this).prop('checked', false);
                            $(this).next().removeClass('active');
                        });

                        // Add active class to the current checkbox's next sibling
                        $(this).next().addClass('active');
                    } else {
                        $(this).next().removeClass('active');
                    }
                });

                // Check on page load
                var firstCheckedFound = false;
                $('.wpgmp_tab_item input[type="checkbox"]').each(function () {
                    if ($(this).is(':checked')) {
                        $(this).next().addClass('active');

                        // if (!firstCheckedFound) {
                        //     $(this).next().addClass('active');
                        //     firstCheckedFound = true;
                        // } else {
                        //     $(this).prop('checked', false).trigger('change');
                        //     $(this).next().removeClass('active');
                        // }
                    }
                });

                clearInterval(waitForMap);
            }
        }, 100);
    }
});